import React, { useState, useEffect} from "react";
import "../header/Header.css";
import "../footer/Footer.css";
import "./styles.css";
import es_ES from '../lang/es_ES.json';
import Footer from "./Footer";
import Header from "../header/Header";
import { LanguageContext } from "../LanguageContext";

export const Services = (props) =>{
    const [language,setLanguage] = useState(()=>{
        let lang = JSON.parse(window.localStorage.getItem("lang"));
        return lang ? lang : es_ES;
    });
    useEffect(() => {
        const listenStorageChange = () => {
          if (localStorage.getItem("lang") === null) {
            setLanguage(es_ES);
          } else {
            setLanguage(JSON.parse(window.localStorage.getItem("lang")));
          }
        };
        window.addEventListener("storage", listenStorageChange);
        return () => window.removeEventListener("storage", listenStorageChange);
      }, []);
    return (
        <div className="App">
            <LanguageContext.Provider value={language}>
                <Header language={language}/>
                <div className="containerServices">
                  <div className="title">
                      <h3>{language.servicios}</h3>
                  </div>

                  <div className="containerInfoServices">
                      <div className="franjaServices">
                          <div className="contentInfoService">
                              <div className="textInfoService">
                                  <div className="descriptionService">
                                    <div className="titleService">
                                        <h3>{language.animation}</h3>
                                    </div>
                                      <p>{language.animation_content}</p>
                                  </div>
                              </div>
                              <div className="imgService desktop">
                                  <iframe width="560" height="315" src="https://www.youtube.com/embed/RymizBUCd-c" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe>
                              </div>
                          </div>
                      </div>

                      
                      <div className="contentInfoService ">
                          <div className="imgDesign desktop rot">
                              <img src="../images/services/Escala personajes.jpg" alt=""/>
                          </div>
                          <div className="textInfoService rot">
                              <div className="descriptionService black">
                                <div className="titleService right">
                                    <h3>{language.design}</h3>
                                </div>
                                  <p>{language.design_content}</p>
                              </div>
                          </div>
                      </div>

                      <div className="franjaServices degArts changeColor">
                          <div className="contentInfoService degContentArts">
                              <div className="imgConceptArts desktop  marginTopArts">
                                  <img src="./images/services/concept_arts.png" alt=""/>
                              </div>
                              <div className="textInfoService">
                                  <div className="descriptionService">
                                    <div className="titleService">
                                        <h3>{language.concept_arts}</h3>
                                    </div>
                                      <p>{language.concept_arts_content}</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                      
                      <div className="contentInfoService">
                          <div className="textInfoService rot">
                              <div className="descriptionService black">
                                <div className="titleService right">
                                    <h3>{language.guiones}</h3>
                                </div>
                                  <p>{language.guiones_content}</p>
                              </div>
                          </div>

                          <div className="imgGuiones desktop rot">
                              <img src="./images/services/Las entradas a Banacuaco-12-c.jpg" alt=""/>
                          </div>
                      </div>

                      <div className="franjaServices ">
                          <div className="contentStoryBoards">
                              <div className="textInfoHistory">
                                  <div className="descriptionService">
                                    <div className="titleService">
                                        <h3>{language.story_boards}</h3>
                                    </div>
                                      <p>{language.story_boards_content}</p>
                                  </div>
                              </div>
                              <div className="imgStory">
                                  <img src="./images/services/Story board_El Jardin Interior.png" alt=""/>
                              </div>
                          </div>
                      </div>
                          <div className="contentPostproduction">
                              <div className="imgContent">
                                <img src="./images/services/Geo Robots Imagen_5.png" alt=""/>
                              </div>
                              <div className="textPostproduction">
                                  <div className="titleService postproductionTitle  descriptionService">
                                      <h3>{language.postproduccion}</h3>
                                      <p>{language.postproduccion_content}</p>
                                  </div>
                              </div>
                          </div>
                      <div className="contentContact">
                          <div className="buttonContact">
                              <a href="/contactUs">{language.contactenos}</a>
                          </div>
                      </div>

                  </div>

              </div>
                <Footer language={language} addClass="color"/>
            </LanguageContext.Provider>
        </div>
    );
}