import React, { useContext } from "react";
import "./Footer.css";
import { LanguageContext } from '../LanguageContext';

export default function Footer(props) {
  const { language } = useContext(LanguageContext);
  
    return (
      <div className={`footer`}>
        <h1>{language.page_1_footer}</h1>
        <div className="socialMedia">
        <div className="icon">
          <a href="https://www.facebook.com/bioideaz/" target="_blank">
            <img src="/images/facebook.svg" alt="facebook" width={30} height={30}/>
          </a>
        </div>
        <div className="icon">
          <a href="https://www.instagram.com/bioideaz_animation/" target="_blank">
            <img src="/images/instagram.svg" alt="instagram" width={30} height={30}/>
          </a>
        </div>
        <div className="icon">
          <a href="https://www.youtube.com/@Bioideaz" target="_blank">
            <img src="/images/youtube.svg" alt="youtube" width={30} height={30}/>
          </a>
        </div>
      </div>
      </div>
    );
}
