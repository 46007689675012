import React, { useState, useEffect} from "react";
import "../header/Header.css";
import "../footer/Footer.css";
import es_ES from '../lang/es_ES.json';
import Footer from "./Footer";
import Header from "../header/Header";
import { LanguageContext } from "../LanguageContext";
import "./styles.css";

export const Gallery = (props) =>{
    const [language,setLanguage] = useState(()=>{
        let lang = JSON.parse(window.localStorage.getItem("lang"));
        return lang ? lang : es_ES;
    });
    useEffect(() => {
        const listenStorageChange = () => {
          if (localStorage.getItem("lang") === null) {
            setLanguage(es_ES);
          } else {
            setLanguage(JSON.parse(localStorage.getItem("lang")));
          }
        };
        window.addEventListener("storage", listenStorageChange);
        return () => window.removeEventListener("storage", listenStorageChange);
      }, []);
    const openPopUp = () =>{

    }
    return (
        <div className="App">
            <LanguageContext.Provider value={language}>
                <Header language={language}/>
                <div className="containerGallery">
                  <div className="title">
                    <h3>{language.gallery_title}</h3>
                  </div>
                  <div className="contentGallery">
                    {
                            language.elements[0].gallery.length > 0 && 
                            language.elements[0].gallery.map((item,index)=>{
                                    return <div className="infoGallery" key={index}>
                                            <img src={item.src} onClick={openPopUp} alt=""/>
                                          </div>
                            })
                        }
                  </div>
                </div>
                <Footer language={language} addClass=""/>
            </LanguageContext.Provider>
        </div>
    );
}