import React, { useState, useEffect} from "react";
import "../header/Header.css";
import "../footer/Footer.css";
import es_ES from '../lang/es_ES.json';
import Footer from "./Footer";
import Header from "../header/Header";
import { LanguageContext } from "../LanguageContext";
import "./styles.css";
import { useNavigate } from "react-router-dom";

export const Productions = ({...props}) =>{
    const [language,setLanguage] = useState(()=>{
        let lang = JSON.parse(window.localStorage.getItem("lang"));
        return lang ? lang : es_ES;
    });
    const [keyIndex,setKeyIndex] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const listenStorageChange = () => {
          if (window.localStorage.getItem("lang") === "") {
            setLanguage(es_ES);
          } else {
            setLanguage(JSON.parse(window.localStorage.getItem("lang")));
          }
        };
        window.addEventListener("storage", listenStorageChange);

        return () => window.removeEventListener("storage", listenStorageChange);
      }, []);
    const openPopUp = (index) =>{
        setKeyIndex(index);
        navigate(`./${index}`);
    }
    return (
        <div className="App">
            <LanguageContext.Provider value={language}>
                <Header language={language}/>
                <div className="containerProduction">
                    <div className="title">
                        <h3>{language.producciones}</h3>
                    </div>
                    <div className="contentProduction">
                        {
                            language.elements[0].production.length > 0 && 
                            language.elements[0].production.map((item,index)=>{
                                    return <div key={item.id} className="infoProduction" onClick={()=>{openPopUp(index)}}>
                                                <img src={item.src} alt=""/>
                                                <p>{item.title}</p>
                                                <p>{item.subtitle}</p>
                                                <p>{item.year}</p>
                                            </div>
                            })
                        }
                    </div>
                </div>
                <Footer language={language} addClass=""/>
            </LanguageContext.Provider>
        </div>
    );
}