import React from "react";
import "../footer/Footer.css";

export default function Footer(props) {
  return (
    <div className={`footer ${props.addClass}`}>
      <h1>{props.language.page_1_footer}</h1>
      <div className="socialMedia">
        <div className="icon">
          <a href="https://www.facebook.com/bioideaz/" target="_blank">
            <img src="" alt="facebook" width={30} height={30}/>
          </a>
        </div>
        <div className="icon">
          <a href="https://www.instagram.com/bioideaz_animation/" target="_blank">
            <img src="" alt="instagram" width={30} height={30}/>
          </a>
        </div>
        <div className="icon">
          <a href="https://www.youtube.com/@Bioideaz" target="_blank">
            <img src="" alt="youtube" width={30} height={30}/>
          </a>
        </div>
      </div>
    </div>
  );
}
