
import React, { useState, useEffect} from "react";
import { useParams } from 'react-router-dom';
import "../../header/Header.css";
import "../../footer/Footer.css";
import es_ES from '../../lang/es_ES.json';
import Footer from "../Footer";
import Header from "../../header/Header";
import { LanguageContext } from "../../LanguageContext";
import "../styles.css";
 
export const Posts = ({params}) => {
  const param = useParams();
  const [language,setLanguage] = useState(()=>{
    let lang = JSON.parse(window.localStorage.getItem("lang"));
    return lang ? lang : es_ES;
  });

  useEffect(() => {
    const listenStorageChange = () => {
      if (window.localStorage.getItem("lang") === "") {
        setLanguage(es_ES);
      } else {
        setLanguage(JSON.parse(window.localStorage.getItem("lang")));
      }
    };
    window.addEventListener("storage", listenStorageChange);

    return () => window.removeEventListener("storage", listenStorageChange);
  }, []);
  return  (<div className="App">
            <LanguageContext.Provider value={language}>
                <Header language={language}/>
                  {/**criaturas del caribe */}
                  <div className="popUp">
                        <div className="containerProduction">
                            <div className="imgHome">
                                <img src={language.elements[0].production[param.id].popup.containerProductionImg} alt=""/>
                            </div>
                            <div className="title">
                                <p className="titlesText">{language.elements[0].production[param.id].popup.title}</p>
                            </div>
                            <div className="textSinopsis">
                                <p>{language.elements[0].production[param.id].popup.content}</p>
                            </div>
                            <div className="containerInfoServices">
                                <div className="franjaServicesPopUp">
                                    <div className="contentInfoImg">
                                        <div className="imgService desktop">
                                            <iframe width="350" height="196" src={language.elements[0].production[param.id].popup.videoSrc} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="idea">
                                    <h3 className="titlesText">{language.elements[0].production[param.id].popup.ideaTitle}</h3>
                                    <p className="textIdea">{language.elements[0].production[param.id].popup.ideaContent}</p>
                                    <div className="imgidea">
                                            <img src={language.elements[0].production[param.id].popup.ideaImgSrc} alt=""/>
                                    </div>
                                </div>
                                <div className="containerCharacters">
                                    <div className="contentInfoCharacters">                      
                                        <div className="textInfo">
                                            <div className="titleChart">
                                                <h3>{language.elements[0].production[param.id].popup.charactersTitle}</h3>
                                            </div>
                                                {
                                                    language.elements[0].production[param.id].popup.characters.map((item,index)=>{
                                                        return <div className="containerDescriptionA" key={`char_${index}`}>
                                                                    <div className="contentDescriptionA ">
                                                                    </div>
                                                                    { item.images.map((itmChr,indexChr)=>{
                                                                        return <div className="imgA" key={`imgChr_${indexChr}`}>
                                                                                  <img src={itmChr.src} alt=""/>
                                                                                </div>
                                                                      })
                                                                    }
                                                                    <div className="txtA">
                                                                        <h3 style={{color: item.titleColor}}>{item.name}</h3>
                                                                    </div>
                                                                    <div className="contentA">
                                                                        <p>{item.description}</p>
                                                                    </div>
                                                                </div>
                                                    })
                                                }    
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="titleChart">
                                <h3>{language.elements[0].production[param.id].popup.backgroundTitle}</h3>
                            </div>
                            <div className="containerMoreInfo">
                                <div className="imgAFondo">
                                    {language.elements[0].production[param.id].popup.backgrounds.map((item,index)=>{
                                        return <div key={`blank_bg_${index}`}>
                                                <p className="textFondos" key={`p_bkg_${index}`}>{item.title}</p>
                                                {
                                                    item.images.map((it,ind)=>{
                                                        return <img key={`backg_${ind}`} src={it.src} alt=""/>;
                                                    })
                                                }
                                                </div>
                                    })
                                    }
                                </div>                
                            </div>

                        </div>
                              
                    </div>
              <Footer language={language} addClass=""/>
            </LanguageContext.Provider>
          </div>
          )
}