import React, { useEffect, useState} from "react";
import "./styles.css";

import Header from "./components/header/Header";
import Main from "./components/main/Main";
import Footer from "./components/footer/Footer";
import { LanguageContext } from './components/LanguageContext';
import es_ES from './components/lang/es_ES.json';

export default function App() {
  const [language,setLanguage] = useState(es_ES);
  const value = { language, setLanguage };

  useEffect(()=>{
    //localStorage.setItem("lang",JSON.stringify(language));
  },[language]);

  useEffect(()=>{
    let language = es_ES;
    if(language){
      setLanguage(language);
      localStorage.setItem("lang",JSON.stringify(language));
    }
  },[]);

  return (
    <div className="App">
      <LanguageContext.Provider value={value}>
        <Header />
        <Main />
        <Footer />
      </LanguageContext.Provider>
    </div>
  );
}