import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { Productions } from "./components/pages/Productions";
import { Services } from "./components/pages/Services";
import { AboutUs } from "./components/pages/AboutUs";
import { Gallery } from "./components/pages/Gallery";
import { ContactUs } from "./components/pages/ContactUs";
import { Posts } from "./components/pages/productions/id"

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App/>}/>
        <Route path="/productions" element={<Productions/>}/>
        <Route path="/services" element={<Services/>}/>
        <Route path="/aboutUs" element={<AboutUs/>}/>
        <Route path="/gallery" element={<Gallery/>}/>
        <Route path="/contactUs" element={<ContactUs/>}/>
        <Route exact path="/productions/:id" element={<Posts/>}/>
      </Routes>
    </Router>
  </React.StrictMode>
);